<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="sousuo-beijing">
        <div class="sousuo-beijing-kuang">
            <el-input v-model="soulist.keyword" placeholder="请输入搜索内容"></el-input>
            <img @click="souso()" style="width: 16px;height: 16px;" src="../assets/image/sousuochakan.png"/>
        </div>
    </div>
    <div class="search-list">
        <!-- 大标题 -->
        <div class="search-title-list">
            <div class="search-title" :class="typeindex == 1 ? 'timezctive' : ''">
                <div class="search-title-quie" @click="genjinxu(1)">
                    <img class="search-img" src="../assets/image/sousuotu01.png"/>
                    <div>规则中心</div>
                </div>
            </div>
            <div class="search-title" :class="typeindex == 2 ? 'timezctive' : ''" >
                <div class="search-title-quie" @click="genjinxu(2)">
                    <img class="search-img" src="../assets/image/suosoutu02.png"/>
                    <div>课程中心</div>
                </div>
            </div>
            <div class="search-title" :class="typeindex == 3 ? 'timezctive' : ''">
                <div class="search-title-quie"  @click="genjinxu(3)">
                    <img class="search-img" src="../assets/image/sousuotu03.png"/>
                    <div>帮助中心</div>
                </div>
            </div>
        </div>
        <!-- 内容展示区 -->
        <!-- 规则中心 -->
        <div  v-if="typeindex == 1">
            <div class="search-neirong-list" v-for="(item,index) in RuleCenter" :key="index" @click="guizetiao(item)">
                <div class="search-neirong-list-lig">{{item.title}}</div>
                <div class="search-neirong-list-lier xianzhi" v-html="item.details"></div>
                <div class="search-number-time">
                    <div>{{item.send_time}}</div>
                    <div v-if="item.id==21">
                        <img style="width: 14px;height: 14px;" src="../assets/image/huiyan.png"/>
                        <span>{{item.browse_number}}人看过</span>
                    </div>
                </div>
            </div>
            <div class="qurshengya-list" v-if="RuleCenter.length==0">
              <img style=" width: 417px; height: 365px;" src="../assets/image/wushujusousuo.png"/>
              <div class="weisousuo">未搜索到相关结果</div>
              <div class="jianyixiugai">建议您修改搜索关键词后重新再试～</div>
            </div>
        </div>
        <!-- 课程中心 -->
        <div v-if="typeindex==2">
            <div class="search-kczxin-list" v-for="(item,index) in CourseCenter" :key="index">
                <div class="search-neirong-kczxin-list">
                    <div class="search-neirong-kczxin-img" @click="kechengcenter(item)"><img :src="item.img"/></div>
                    <div class="search-neirong-kczxin-xuanzhong">
                        <div class="search-neirong-kczxin">
                            <div>{{item.title}}</div>
                            <div>{{item.send_time}}</div>
                        </div>
                        <div class="search-neirong-kczxin-size xianzhi" v-html="item.details"></div>
                        <div class="search-neirong-kczxin-renshu">
                            <img style="width: 14px;height: 14px;margin-right: 8px;" src="../assets/image/huiyan.png"/>
                            <div>{{item.browse_number}}人看过</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="qurshengya-list" v-if="CourseCenter.length==0">
              <img style=" width: 417px; height: 365px;" src="../assets/image/wushujusousuo.png"/>
              <div class="weisousuo">未搜索到相关结果</div>
              <div class="jianyixiugai">建议您修改搜索关键词后重新再试～</div>
            </div>
        </div>
        <!-- 帮助中心 -->
        <div  v-if="typeindex == 3">
            <div class="search-neirong-list" v-for="(item,index) in HelpCenter" :key="index" @click="hlep(item)">
                <div class="search-neirong-list-lig">{{item.title}}</div>
                <div class="search-neirong-list-lier xianzhi" v-html="item.details"></div>
                <div class="search-number-time">
                    <div>{{item.send_time}}</div>
                    <!-- <div>
                        <img style="width: 14px;height: 14px;" src="../assets/image/huiyan.png"/>
                        <span>{{item.browse_number}}人看过</span>
                    </div> -->
                </div>
            </div>
            <div class="qurshengya-list" v-if="HelpCenter.length==0">
              <img style=" width: 417px; height: 365px;" src="../assets/image/wushujusousuo.png"/>
              <div class="weisousuo">未搜索到相关结果</div>
              <div class="jianyixiugai">建议您修改搜索关键词后重新再试～</div>
            </div>
        </div>
    </div>
     <!-- 分页 -->
      <div class="fenye-list">
        <Pagination
          :total="total"
          :page.sync="soulist.page"
          :limit.sync="soulist.limit"
          @pagination="getsousuolist"
        />
      </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
  export default {
    name:"indexsearch",
    components: { Pagination,},
    data () {
      return {
        input:'',
        typeindex:1,
        total:0,
        soulist:{
          page:1,
          limit:16,
          keyword:''
        },
        RuleCenter:[],  //规则中心
        CourseCenter:[],  //课程中心
        HelpCenter:[]   //帮助中心
      }
    },
    mounted () {
     this.getsousuolist()
    },
    methods: {
      // 切换分类
        genjinxu(e) {
            this.typeindex = e;
            this.getsousuolist()
        },
      souso(){
        this.getsousuolist()
      },
      kechengcenter(data){
        this.$router.push('/aboutdetails?id='+data.id)
      },
      hlep(data){
        this.$router.push('/helpzhxin?id='+data.id)
      },
      guizetiao(data){
        if(data.id==21){
          this.$router.push('/caseyushutdetails?id='+data.id)
        }else{
           this.$router.push('/guizedetail?id='+data.id)
        }
      },
      //搜索列表
      getsousuolist(){
        let that = this
        that.$http({
          url: that.baseurl +"/searchIndex?page="+ that.soulist.page +'&limit='+that.soulist.limit +'&keyword='+that.soulist.keyword,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  that.total = (that.typeindex == 1? res.data.data.rule.total :that.typeindex == 2? res.data.data.course.total:that.typeindex == 3?res.data.data.help.total:null)
                  // console.log(that.total,'******')
                  that.RuleCenter = res.data.data.rule.data
                  that.CourseCenter = res.data.data.course.data
                  that.HelpCenter = res.data.data.help.data
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    background-color: #F2F5FB ;
  }
  .sousuo-beijing{
    width: 100%;
    height: 418px;
    background: url('../assets/image/sousuobeiijingtu.png') no-repeat; 
    background-size: cover;
    position: relative;
  }
  .sousuo-beijing-kuang{
    position: absolute;
    top:50%;
    left:40% ;
    width: 570px;
    height: 56px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DCDFE6;
    padding: 0 20px;
  }

  .search-list{
    width: 100%;
    max-width: 1520px;
    min-height: 600px;
    /* height: 500px; */
    margin: 0px auto;
    padding-bottom: 20px;
  }
  .search-title-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top:40px ;
    margin-bottom: 40px;
  }
  .search-title{
    width: 32.5%;
    height: 156px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    text-align: center;
    font-weight: 550;
    font-size: 20px;
    color: #333333;
    margin-top: 10px;
  }
  .search-title-quie{
    margin: 25px 0;
  }
  .search-img{
    width: 68px;
    height: 68px;
  }
  .search-neirong-list{
    width: 100%;
    height: 135px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
    padding: 32px 24px 24px 24px;
  }
  .search-neirong-list-lig{
    font-weight: 550;
    font-size: 24px;
    color: #333333;
  }
  .search-neirong-list-lier{
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    padding: 20px 0 27px 0;
  }
  .xianzhi{
    width: 90%;
    height: 16px;
    overflow:hidden;
    word-wrap: break-word;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;  
  }
  .search-number-time{
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 14px;
    padding: 10px 0;
  }
  .search-number-time div:nth-child(1){
    margin-right: 50px;
  }
  .search-number-time div:nth-child(2){
    display: flex;
    align-items: center;
  }
  .search-number-time div:nth-child(2) img{
    margin-right: 8px;
  }
  .search-kczxin-list{
    width: 100%;
    height: 190px;
    background-color: #FFFFFF;
    margin-bottom: 20px;

  }
  .search-neirong-kczxin-list{
    display: flex;
    justify-content: space-between;
  }
  .search-neirong-kczxin-img{
    width: 25%;
  }
  .search-neirong-kczxin-img img{
    width: 100%;
    height: 190px;
  }
  .search-neirong-kczxin-xuanzhong{
    width: 75%;
  }
  .search-neirong-kczxin{
    display: flex;
    justify-content: space-between;
    padding: 26px;
  }
  .search-neirong-kczxin div:nth-child(1){
    font-weight: 550;
    font-size: 24px;
    color: #333333;
  }
  .search-neirong-kczxin div:nth-child(2){
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  .search-neirong-kczxin-size{
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    padding: 0px 26px 26px 26px;
  }
  .search-neirong-kczxin-renshu{
    padding: 0px 26px 26px 26px;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
  }
  .timezctive {
    color:#275AF5;
    background: url('../assets/image/beijingtuxuanzhong01.png') no-repeat;
    padding: 10px 0;
    background-size: 100% 100%;
    box-shadow: none;
  }
  .qurshengya-list{
    text-align: center;
    margin-top: 100px;
  }
  .weisousuo{
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    padding: 10px 0;
  }
  .jianyixiugai{
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
  .fenye-list{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
  }
</style>
<style >
.sousuo-beijing-kuang .el-input__inner{
    width: 85%;
    height: 56px !important;
    border-radius: 8px !important;
    border: none !important;
    font-size: 18px;
    font-weight: 550;
    color: #333333 ;
}

</style>
